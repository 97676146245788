import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function () {
  return (
    <footer className="footer">
      <div className="container">
        <div className="logo">
          <a href="https://www.nimmerland.live" title="Nimmerland UG">
            <StaticImage
              // This is a presentational image, so the alt should be an empty string
              alt=""
              width={200}
              // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
              src="../images/Nimmerland-white.png"
              formats={["auto", "webp", "avif"]}
            />
          </a>
        </div>
        <div className="logo">
          <a
            href="https://chaosleague.org/manifest/"
            title="Chaos League"
          >
            <StaticImage
              // This is a presentational image, so the alt should be an empty string
              alt=""
              width={250}
              // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
              src="../images/southern-way.png"
              formats={["auto", "webp", "avif"]}
            />
          </a>
        </div>
        <div className="navlinks text-secondary">
          <ul>
            <li>
              <a href="https://www.nimmerland.live/privacy/" target="_blank">
                <span>Datenschutzerklärung</span>
              </a>
            </li>
            <li>
              <a href="https://www.nimmerland.live/tos/" target="_blank">
                <span>Teilnahmebedingungen</span>
              </a>
            </li>
          </ul>
        </div>
        <p className="text-primary f-d">
          &copy; Nimmerland UG {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  )
}
